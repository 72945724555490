import React, { useState } from "react";
import "./Auth.css";
import { Col, Container, Button, Form, Row } from "react-bootstrap";
import New_Passqword from "../../img/login.jpg";
import { NavLink, useLocation } from "react-router-dom";
import { authServices } from "../../APIServices/authServices";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  const handleSubmit = async (e) => {
    let userData = {};
    userData.token = token;
    userData.newPassword = newPassword;
    userData.repeatPassword = repeatPassword;
    let result = await authServices.resetPassword(userData);
    if (result.statusCode === 200) {
      setNewPassword("");
      setRepeatPassword("");
      window.location.href = "/login";
    }
  };

  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;

    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };

  const logout = async () => {
    await authServices.logout();
  }

  return (
    <>
      <Container fluid className="p-0 section__Module">
        <div className="hero">
          <div className="overlay-black"></div>
          <img className="hero-img" src={New_Passqword} alt="Hero" />
        </div>
        <section className="sign-up">
          <form className="sign-up__form" action="#">
            <h1>Create New Password</h1>
            <Row>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3 eye_password relative">
                <Form.Label>New Password <span>*</span></Form.Label>
                <Form.Control
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter New Password"
                  onChange={(e) => { setNewPassword(e.target.value); validatePassword(e.target.value) }}
                  value={newPassword}
                />
                {error || newPassword?.length === 0 ? (
                  <span style={{ fontSize: 10, color: "red" }}>
                    Password Must have 1 Capital Letter, 1 Small Letter, 1 Special
                    Character with 8 Character
                  </span>
                ) : (
                  <span style={{ fontSize: 10, color: "green" }}>
                    Strong Password
                  </span>
                )}
                <i onClick={togglePasswordVisibility}>
                  {showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className='fa-solid fa-eye'></i>}
                </i>
              </Form.Group>
              <Form.Group as={Col} md="12" className="add_sponsor_field mb-3 eye_password relative">
                <Form.Label>Confirm Password <span>*</span></Form.Label>
                <Form.Control
                  required
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  value={repeatPassword} />
                <i onClick={toggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className='fa-solid fa-eye'></i>}
                </i>
                {newPassword === repeatPassword ?
                  newPassword?.length === 0 ? "" :
                    <span style={{ fontSize: 10, color: "green" }}>
                      Password Match
                    </span>
                  :
                  <span style={{ fontSize: 10, color: "red" }}>
                    Password Not Match
                  </span>
                }
              </Form.Group>
            </Row>
            <div className="footer_button">
              <Button className="mb-3 w-100"
                disabled={!newPassword || !repeatPassword}
                onClick={handleSubmit}
              >
                Reset password
              </Button>
            </div>
            <p className="message">
              <NavLink onClick={logout}>Back to Login</NavLink>
            </p>
          </form>
        </section>
      </Container>
    </>
  );
};

export default ResetPassword;
