import React, { useEffect, useState } from "react";
import AddNewClient from "./AddNewClient";
import UseToggle from "../Hooks/useToggle";
import Pagination from "../Hooks/Pagination";
import ClientListTable from "./ClientListTable";
import TableHeader from "../../component/TableHeader";
import { userServices } from "../../APIServices/usersServices";
import ExportLogDataModal from "../Account/LogData/ExportLogDataModal";

const Client = ({ onMouseDown, tableRef }) => {
  const [clientList, setClientList] = useState([]);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();

  const getAllClient = async () => {
    let data = await userServices.getAllClient();
    setClientList(data?.data);
  };

  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = clientList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(clientList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % clientList?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    getAllClient();
  }, []);
 
  return (
    <>
      <TableHeader Export handleExport={setToggle}
        AddNew
        handleAddNew={setToggle1}
        titleAddNew="Add New Client" />
      <ClientListTable
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        clientList={currentItems}
      />
      <Pagination
        Result={clientList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <ExportLogDataModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Export Log History"}
        Submit={"Export"}
      />
      <AddNewClient
        Show={!toggle1}
        Hide={setToggle1}
        Size={"lg"}
        Title={"Add New Client"}
        getAllClient={getAllClient}
        Submit={"Submit"}
      />
    </>
  );
};

export default Client;
