import React, { useEffect, useState } from "react";
import UseToggle from "../Hooks/useToggle";
import Pagination from "../Hooks/Pagination";
import WorkFlowTableData from "./WorkFlowTableData";
import TableHeader from "../../component/TableHeader";
import { userServices } from "../../APIServices/usersServices";

const WorkFlowData = ({ onMouseDown, tableRef }) => {
  const [workflowData, setWorkflowData] = useState([]);
  const { setToggle} = UseToggle();

  const getAllWorkflow = async () => {
    let data = await userServices.getAllWorkflow();
    setWorkflowData(data?.data);
  };



  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = workflowData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(workflowData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % workflowData?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    getAllWorkflow();
  }, []);
 
  return (
    <>
      <TableHeader Export handleExport={setToggle}/>
      <WorkFlowTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        workflowData={currentItems}
        getAllWorkflow={getAllWorkflow}
      />
      <Pagination
        Result={workflowData}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
    </>
  );
};

export default WorkFlowData;
