import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { addressServices } from "../../APIServices/addressServices";
import { userServices } from "../../APIServices/usersServices";

const AddNewUserModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getUserData,
  role,
}) => {
  let [companyName, setCompanyName] = useState("");
  let [shortName, setShortName] = useState("");
  let [website, setWebsite] = useState("");
  let [officePhone, setOfficePhone] = useState("");
  let [firstName, setFirstName] = useState("");
  let [middleName, setMiddleName] = useState("");
  let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [gender, setGender] = useState("");
  let [address, setAddress] = useState("");
  let [countryId, setCountryId] = useState("");
  let [stateId, setStateId] = useState("");
  let [cityId, setCityId] = useState("");
  let [zipCode, setZipCode] = useState("");
  let [countryList, setCountryList] = useState([]);
  let [stateList, setStateList] = useState([]);
  let [cityList, setCityList] = useState([]);
  let [emailError, setEmailError] = useState("");
  let [phoneError, setPhoneError] = useState("");
  let [officePhoneError, setOfficePhoneError] = useState("");

  const addNewUser = async () => {
    let userData = {};
    if (role === "organization") {
      userData.companyName = companyName;
      userData.shortName = shortName;
      userData.website = website;
      userData.officePhone = officePhone;
    } else {
      userData.firstName = firstName;
      userData.middleName = middleName;
      userData.lastName = lastName;
      userData.gender = gender;
    }
    userData.email = email;
    userData.phone = phoneNumber;
    userData.address = address;
    userData.countryId = countryId;
    userData.stateId = stateId;
    userData.cityId = cityId;
    userData.zipCode = zipCode;
    userData.roleType = role;
    let data = await userServices.addNewUser(userData);
    if (data?.statusCode === 200) {
      clearInputField();
      getUserData();
      Hide();
    }
  };

  const clearInputField = () => {
    if (role === "organization") {
      setCompanyName("");
      setShortName("");
      setWebsite("");
      setOfficePhone("");
    } else {
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setGender("");
    }
    setEmail("");
    setPhoneNumber("");
    setAddress("");
    setCountryId("");
    setStateId("");
    setCityId("");
    setZipCode("");
    setEmailError("");
    setPhoneError("");
    setOfficePhoneError("");
  };

  const getCountries = async () => {
    let data = await addressServices.getCountries();
    setCountryList(data?.data);
  };

  const getStates = async (id) => {
    let data = await addressServices.getStates(id);
    setStateList(data?.data);
  };

  const getCities = async (id) => {
    let data = await addressServices.getCities(id);
    setCityList(data?.data);
  };

  const validatEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
    } else {
      setEmailError("Invalide Email,Please Enter valid Email");
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;
    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number Should have 10 digit");
    }
  };

  const validateOfficePhoneNumber = (officePhone) => {
    const officephonePattern = /^\d{10}$/;
    if (officephonePattern.test(officePhone)) {
      setOfficePhoneError("");
    } else {
      if (officePhone?.length === 0) {
        setOfficePhoneError("");
      } else {
        setOfficePhoneError(" Office Phone Number Should have 10 digit");
      }
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div
          onClick={() => {
            Hide();
            clearInputField();
          }}
          className="close_modal"
          title="Close"
        >
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {role === "organization" ? (
            <>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  Organization Name <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                  placeholder="Enter Organization Name"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  Short Name <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setShortName(e.target.value)}
                  value={shortName}
                  placeholder="Enter Short Name"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  required
                  type="url"
                  onChange={(e) => setWebsite(e.target.value)}
                  value={website}
                  placeholder="Enter Website"
                />
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  First Name <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  placeholder="Enter First Name"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setMiddleName(e.target.value)}
                  value={middleName}
                  placeholder="Enter Middle Name"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  Last Name <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  placeholder="Enter Last Name"
                />
              </Form.Group>
            </>
          )}
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Email <span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Enter Email ID"
              onChange={(e) => {
                setEmail(e.target.value);
                validatEmail(e.target.value);
              }}
              value={email}
            />
            {emailError && (
              <span className="message" style={{ color: "red", fontSize: 10 }}>
                {emailError}
              </span>
            )}
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Phone Number <span>*</span>
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                validatePhoneNumber(e.target.value);
              }}
              value={phoneNumber}
              type="number"
              placeholder="Enter Phone Number"
            />
            {phoneError && (
              <span className="message" style={{ color: "red", fontSize: 10 }}>
                {phoneError}
              </span>
            )}
          </Form.Group>
          {role === "organization" ? (
            <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
              <Form.Label>Office Phone</Form.Label>
              <Form.Control
                required
                onChange={(e) => {
                  setOfficePhone(e.target.value);
                  validateOfficePhoneNumber(e.target.value);
                }}
                value={officePhone}
                type="number"
                placeholder="Enter Office Phone"
              />
              {officePhoneError && (
                <span
                  className="message"
                  style={{ color: "red", fontSize: 10 }}
                >
                  {officePhoneError}
                </span>
              )}
            </Form.Group>
          ) : (
            <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
              <Form.Label>
                Gender <span>*</span>
              </Form.Label>
              <Form.Select
                onChange={(e) => setGender(e.target.value)}
                aria-label="Default select example"
              >
                <option>Select Gender</option>
                <option selected={gender === "male"} value="male">
                  Male
                </option>
                <option selected={gender === "female"} value="female">
                  Female
                </option>
                <option selected={gender === "other"} value="other">
                  Other
                </option>
              </Form.Select>
            </Form.Group>
          )}
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Address <span>*</span>
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              as="textarea"
              placeholder="Enter Address"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Country <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setCountryId(e.target.value)}
              onClick={(e) => getStates(e.target[e.target.selectedIndex].title)}
              aria-label="Default select example"
            >
              <option>Select Country</option>
              {countryList?.length > 0
                ? countryList?.map((item) => (
                    <option
                      selected={item._id === countryId}
                      value={item._id}
                      title={item.id}
                    >
                      {item.name}
                    </option>
                  ))
                : ""}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              State <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setStateId(e.target.value)}
              onClick={(e) => getCities(e.target[e.target.selectedIndex].title)}
              aria-label="Default select example"
            >
              <option>Select State</option>
              {stateList?.length > 0
                ? stateList?.map((item) => (
                    <option
                      selected={item._id === stateId}
                      value={item._id}
                      title={item.id}
                    >
                      {item.name}
                    </option>
                  ))
                : ""}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              City <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setCityId(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select City</option>
              {cityList?.length > 0
                ? cityList?.map((item) => (
                    <option selected={item._id === cityId} value={item._id}>
                      {item.name}
                    </option>
                  ))
                : ""}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Zip Code <span>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) => setZipCode(e.target.value)}
              value={zipCode}
              required
              type="number"
              placeholder="Enter Zip Code"
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button onClick={clearInputField} className="close_circle">
            Reset
          </Button>
          {role === "organization" ? (
            <Button
              disabled={
                !companyName ||
                !shortName ||
                !email ||
                !phoneNumber ||
                !address ||
                !countryId ||
                !stateId ||
                !cityId ||
                !zipCode ||
                emailError ||
                phoneError
              }
              onClick={addNewUser}
            >
              {Submit}
            </Button>
          ) : (
            <Button
              disabled={
                !firstName ||
                !lastName ||
                !email ||
                !phoneNumber ||
                !gender ||
                !address ||
                !countryId ||
                !stateId ||
                !cityId ||
                !zipCode ||
                emailError ||
                phoneError
              }
              onClick={addNewUser}
            >
              {Submit}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewUserModal;
