import React from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";

const FailedStatusTableData = ({ failedLoginData, onMouseDown, tableRef }) => {
  return (
    <div className="table-container overflow-auto">
      <Table id="resizable-table" className="m-0 custom-table" ref={tableRef}>
        <thead>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>Sr.</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Message</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Email</th>
            <th onMouseDown={(e) => onMouseDown(e, 5)}>Password</th>
            <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 200 }}>Try Time</th>
          </tr>
        </thead>
        <tbody>
          {failedLoginData?.length > 0 &&
            failedLoginData?.map((item, index) => (
              <tr className="relative">
                <td>{index + 1}</td>
                <td>{item.msg}</td>
                <td>{item.email}</td>
                <td>{item.password}</td>
                <td><Moment format="DD MMM YYYY | HH:mm">
                  {item.timestamp}
                </Moment></td>
              </tr>
            ))}
        </tbody>
      </Table>
      {failedLoginData?.length > 0 ? "" :
        <div className="noRecordFound">Record not found</div>
      }
    </div>
  );
};

export default FailedStatusTableData;
