import React, { useEffect, useState } from "react";
import HomeWidget from "./HomeWidget";
import { Col, Row } from "react-bootstrap";
import {
  TotalUsersChart,
  TotalSubadminChart,
  TotalLogsChart,
} from "../component/Chart";
import { userServices } from "../APIServices/usersServices";
import { loginActivitiesServices } from "../APIServices/loginActivitiesServices";

const Dashboard = ({
  themeColor,
  permission,
  roleType,
  AIUserPermission,
  ConsultantPermission,
  DeveloperPermission,
  OrganizationPermission,
}) => {
  const [subadminChartData, setSubadminChartData] = useState([]);
  const [totalSubadmin, setTotalSubadmin] = useState(0);

  const [organizationChartData, setOrganizationChartData] = useState([]);

  const [reviewerChartData, setReviewerChartData] = useState([]);
  const [totalReviewer, setTotalReviewer] = useState(0);
  const [totalLogouts, setTotalLogouts] = useState(0);
  const [totalLogins, setTotalLogins] = useState(0);
  const [totalChartLogs, setTotalChartLogs] = useState([]);
  const [totalOrganization, setTotalOrganization] = useState(0);
  const [totalLogs, setTotalLogs] = useState(0);
  const [totalFailedLogs, setTotalFailedLogs] = useState(0);

  const getChartOrganizationData = async () => {
    let data = await userServices.getChartUserData("organization");
    setOrganizationChartData(data?.totalChartData);
    setTotalOrganization(data?.totalUserData);
  };

  const getChartReviewerData = async () => {
    let data = await userServices.getChartUserData("reviewer");
    setReviewerChartData(data?.totalChartData);
    setTotalReviewer(data?.totalUserData);
  };
  const getChartSubadminData = async () => {
    let data = await userServices.getChartUserData("subadmin");
    setSubadminChartData(data?.totalChartData);
    setTotalSubadmin(data?.totalUserData);
  };

  const getFailLogin = async () => {
    let data = await loginActivitiesServices.getFailLogin();
    setTotalFailedLogs(data?.data?.length);
  };

  const loginActivities = async () => {
    let data = await loginActivitiesServices.loginActivities();
    setTotalLogins(data?.data?.length);
    const logoutCount = data?.data?.filter(
      (log) => log.type === "logout"
    )?.length;
    setTotalLogouts(logoutCount);
  };

  useEffect(() => {
    getChartReviewerData();
    getChartOrganizationData();
    getChartSubadminData();
    getFailLogin();
    loginActivities();
  }, []);

  return (
    <>
      <HomeWidget
        permission={permission}
        roleType={roleType}
        totalOrganization={totalOrganization}
        totalSubadmin={totalSubadmin}
        totalLogs={totalLogs}
        totalLogouts={totalLogouts}
        totalLogins={totalLogins}
        totalFailedLogs={totalFailedLogs}
        totalReviewer={totalReviewer}
        AIUserPermission={AIUserPermission}
        ConsultantPermission={ConsultantPermission}
        DeveloperPermission={DeveloperPermission}
        OrganizationPermission={OrganizationPermission}
      />

      <Row>
        {totalReviewer > 0 && roleType === "organization" && (
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
              <TotalUsersChart
                title="Reviewer"
                themeColor={themeColor}
                userChartData={reviewerChartData}
              />
            </div>
          </Col>
        )}
        {totalOrganization > 0 &&
          OrganizationPermission &&
          (permission?.dashboardModuleData || roleType === "admin") && (
            <Col xl={3} lg={3} md={6} className="mb-20">
              <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
                <TotalUsersChart
                  title="Organization"
                  themeColor={themeColor}
                  userChartData={organizationChartData}
                />
              </div>
            </Col>
          )}

        {totalSubadmin > 0 && roleType === "admin" && (
          <Col xl={3} lg={3} md={6} className="mb-20">
            <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
              <TotalSubadminChart
                themeColor={themeColor}
                subadminChartData={subadminChartData}
              />
            </div>
          </Col>
        )}
        <Col xl={3} lg={3} md={6} className="mb-20">
          <div className="widget-style1 box-shadow-min border-radius-3 hstack justify-content-center">
            <TotalLogsChart
              themeColor={themeColor}
              totalChartLogs={totalChartLogs}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
