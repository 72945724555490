import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { designationPermissionServices } from "../../../APIServices/designationPermissionServices";

const AccessCheckboxList = ({
  viewAccessId,
  viewAccessList,
  getPermissionsList,
  permissionName,
}) => {
  let [checkboxes, setCheckboxes] = useState({
    //Dashboard Module
    dashboardModuleData: false,

    //Organization Module
    addNewOrganizations: false,
    viewOrganizationsDetails: false,
    updateOrganizationsDetails: false,
    changeOrganizationStatus: false,
    exportAllOrganizationData: false,
    viewOrganizationInvitationHistory: false,
    sendNewOrganizationInvitation: false,
    resendOrganizationInvitation: false,
    cancelOrganizationInvitation: false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    });
  };

  const assignPermissionLists = async () => {
    let userData = {};
    userData.id = viewAccessId;
    userData.permissionLists = checkboxes;
    let data = await designationPermissionServices.assignPermissionLists(
      userData
    );
    if (data?.statusCode === 200) {
      getPermissionsList();
    }
  };

  useEffect(() => {
    setCheckboxes(viewAccessList);
  }, [viewAccessList]);
  return (
    <>
      <div className="MainHeadTitle">
        {permissionName} Permission Level Lists
      </div>
      <div className="permissionList_height">
        <div className="mb-3">
          <p style={{ marginBottom: 10, fontWeight: 600 }}>
            Dashboard Graph & Data:
          </p>
          <Row>
            <Col md={6}>
              <div className="moduleGroup">
                <Form.Check
                  type="checkbox"
                  label="Dashboard Module Data"
                  name="dashboardModuleData"
                  id="dashboardModuleData"
                  checked={checkboxes?.dashboardModuleData}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <p style={{ marginBottom: 10, fontWeight: 600 }}>
                Organizations Module:
              </p>
              <Row>
                <Col md={12}>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Add New Organizations"
                      name="addNewOrganizations"
                      id="addNewOrganizations"
                      checked={checkboxes?.addNewOrganizations}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="View Details"
                      name="viewOrganizationsDetails"
                      id="viewOrganizationsDetails"
                      checked={checkboxes?.viewOrganizationsDetails}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Update Details"
                      name="updateOrganizationsDetails"
                      id="updateOrganizationsDetails"
                      checked={checkboxes?.updateOrganizationsDetails}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Change Status"
                      name="changeOrganizationStatus"
                      id="changeOrganizationStatus"
                      checked={checkboxes?.changeOrganizationStatus}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Export All Organization Data"
                      name="exportAllOrganizationData"
                      id="exportAllOrganizationData"
                      checked={checkboxes?.exportAllOrganizationData}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="View Invitation History"
                      name="viewOrganizationInvitationHistory"
                      id="viewOrganizationInvitationHistory"
                      checked={checkboxes?.viewOrganizationInvitationHistory}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Send New Invitation"
                      name="sendNewOrganizationInvitation"
                      id="sendNewOrganizationInvitation"
                      checked={checkboxes?.sendNewOrganizationInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Resend Invitation"
                      name="resendOrganizationInvitation"
                      id="resendOrganizationInvitation"
                      checked={checkboxes?.resendOrganizationInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Cancel Invitation"
                      name="cancelOrganizationInvitation"
                      id="cancelOrganizationInvitation"
                      checked={checkboxes?.cancelOrganizationInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="assign_permission_button footer_button">
        <Button onClick={assignPermissionLists}>Assign Permission</Button>
      </div>
    </>
  );
};

export default AccessCheckboxList;
