import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const LogTableData = ({ logData, onMouseDown, tableRef }) => {
  return (
    <>
      <div className="table-container overflow-auto">
        <Table ref={tableRef} id="resizable-table" className="custom-table">
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}  style={{ width: 150 }}>IP Address</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Location</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 220 }}>Activity</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)} style={{ width: 120 }}>Type</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 5)}
                className="text-center"
                style={{ width: 130 }}
              >
                Status
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 160 }}>
                In-Out Time
              </th>
            </tr>
          </thead>
          <tbody>
            {logData?.length > 0 &&
              logData?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.ip}</td>
                  <td>
                    {item.location.city}, {item.location.region}, {item.location.country}
                  </td>
                  <td>{item.activity}</td>
                  <td>{item.type}</td>
                  <td className="hstack justify-content-center border">
                    <div
                      className={`text-center text-capitalize ${
                        item.status === "login" ? "login_text" : "logout_text"
                      }`}
                    >
                      {item.status}
                    </div>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss" withTitle>
                      {item.timestamp}
                    </Moment>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {logData?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
    </>
  );
};

export default LogTableData;
