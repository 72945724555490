import config from "../config/config.json";
import saveAs from "file-saver";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody
} from "../APIHelpers/Responses";

export const userServices = {
  // User
  getUsers,
  addClient,
  addUsers,
  updateUser,
  getAllClient,
  getAllWorkflow,
  updateUserData,
  searchUserData,

  //Common
  updateWorkflow,
  changeUserDataStatus,
  deleteUserData,
  changeUserPermission,
  changeUserDesignation,
  getUserData,

  exportUserDataMultiFormat,
  getChartUserData,
};

// Add New User
async function addUsers(userData) {
  const response = await fetch(
    `${config.API_URL}addUsers`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add New Client
async function addClient(userData) {
  const response = await fetch(
    `${config.API_URL}addClient`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Change Status for User
async function changeUserDataStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeUserDataStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Change Status for Workflow
async function updateWorkflow(id, userData) {
  const response = await fetch(
    `${config.API_URL}updateWorkflow/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function updateUser(id, userData) {
  const response = await fetch(
    `${config.API_URL}updateUser/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update User
async function updateUserData(userData) {
  const response = await fetch(
    `${config.API_URL}updateUserData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Delete User
async function deleteUserData(id) {
  const response = await fetch(
    `${config.API_URL}deleteUserData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Change Permission for User
async function changeUserPermission(userData) {
  const response = await fetch(
    `${config.API_URL}changeUserPermission`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Change Designation for User
async function changeUserDesignation(userData) {
  const response = await fetch(
    `${config.API_URL}changeUserDesignation`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Search User
async function searchUserData(userData) {
  const response = await fetch(
    `${config.API_URL}searchUserData`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All User
async function getUserData(roleType) {
  const response = await fetch(
    `${config.API_URL}getUserData/${roleType}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}


async function getUsers() {
  const response = await fetch(
    `${config.API_URL}getUsers`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Client

async function getAllClient() {
  const response = await fetch(
    `${config.API_URL}getAllClient`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}


async function getAllWorkflow() {
  const response = await fetch(
    `${config.API_URL}getAllWorkflow`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Chart User Data
async function getChartUserData(roleType) {
  const response = await fetch(
    `${config.API_URL}getChartUserData/${roleType}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export User in Multiformat
async function exportUserDataMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportUserDataMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}