import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ChangeFont from "./ChangeFont";
import ChangeColor from "./ChangeColor";
import ChangeStatusColor from "./ChangeStatusColor";

const Appearance = ({
  getThemeColor,
  themeColor,
  getThemeFont,
  font,
  getStatusColor,
  activeColor,
  inActiveColor,
  pendingColor
}) => {
  const [activeOptionTab, setActiveOptionTab] = useState("font")

  return (
    <>
      <Container fluid className="p-0">
        <Row>
          <Col xl={3} lg={4} md={5} sm={5} className="mb-20">
            <div className="leaveOptionBox">
              <div onClick={() => setActiveOptionTab("font")} className={activeOptionTab === "font" ? "optionActiveButton" : "optionButton"}>Change Theme Font</div>
              <div onClick={() => setActiveOptionTab("color")} className={activeOptionTab === "color" ? "optionActiveButton" : "optionButton"}>Change Theme Color</div>
              <div onClick={() => setActiveOptionTab("statusColor")} className={activeOptionTab === "statusColor" ? "optionActiveButton" : "optionButton"}>Change Status Color</div>
            </div>
          </Col>
          <Col xl={9} lg={8} md={7} sm={7} className="mb-20">
            <div className="leaveManage">
              {activeOptionTab === "font" ?
                  <ChangeFont
                    getThemeFontData={getThemeFont}
                    fontData={font}
                  />
                  : activeOptionTab === "color" ?
                    <ChangeColor
                      getThemeColorData={getThemeColor}
                      themeColor={themeColor}
                    />
                    : activeOptionTab === "statusColor" ?
                      <ChangeStatusColor
                        getStatusColorData={getStatusColor}
                        activeColorData={activeColor}
                        inActiveColorData={inActiveColor}
                        pendingColorData={pendingColor}
                      />
                      : ""}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Appearance;
