import React, { useEffect, useState } from "react";
import Pagination from "../../Hooks/Pagination";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";
import UseToggle from "../../Hooks/useToggle";
import ExportFaildLoginDataModal from "./ExportFaildLoginDataModal";
import TableHeader from "../../../component/TableHeader";
import FailedStatusTableData from "./FailedStatusTableData";

const FailedStatus = ({onMouseDown, tableRef}) => {
  const { toggle, setToggle } = UseToggle();
  const [failedLoginData, setFailedLoginData] = useState([]);

  const getFailLogin = async () => {
    let data = await loginActivitiesServices.getFailLogin();
    setFailedLoginData(data?.data);
  };

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = failedLoginData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(failedLoginData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % failedLoginData?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    getFailLogin();
  }, []);
  return (
    <>
      <TableHeader Export handleExport={setToggle} />
      <FailedStatusTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        failedLoginData={currentItems}
      />
      <Pagination
        Result={failedLoginData}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <ExportFaildLoginDataModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Export Failed Login History"}
        Submit={"Export"}
      />
    </>
  );
};

export default FailedStatus;
