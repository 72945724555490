import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { authServices } from "../APIServices/authServices";

function Sidebar({
  roleType,
  permalink,
  screenWidth,
  userCurrrentData,
  isSidebarCollapsed,
  setSidebarCollapsed,
  showLargeMenuAccount,
  showLargeMenuSubadmin,
  OrganizationPermission,
  setShowLargeMenuAccount,
  setShowLargeMenuSubadmin,
  setShowLargeMenuEditorControl,
  setShowLargeMenuAIAlphaControl,
}) {
  
  const accountRef = useRef(null);
  const subadminRef = useRef(null);
  const [showMobileMenuAccount, setShowMobileMenuAccount] = useState(false);
  const [showMobileMenuSubadmin, setShowMobileMenuSubadmin] = useState(false);

  const handleMobileSidebar = () => {
    if (screenWidth < 767) {
      setSidebarCollapsed(!isSidebarCollapsed);
    }
  };

  const logout = async () => {
    await authServices.logout();
  };
  

  const handleShowMenuAccount = () => {
    setShowMobileMenuAccount(!showMobileMenuAccount);
    setShowLargeMenuAccount(!showLargeMenuAccount);
    setShowLargeMenuSubadmin(false);
    setShowLargeMenuEditorControl(false);
    setShowLargeMenuAIAlphaControl(false);
  };

  const handleShowMenuSubadmin = () => {
    setShowMobileMenuSubadmin(!showMobileMenuSubadmin);
    setShowLargeMenuSubadmin(!showLargeMenuSubadmin);
    setShowLargeMenuAccount(false);
    setShowLargeMenuEditorControl(false);
    setShowLargeMenuAIAlphaControl(false);
  };

  useEffect(() => {
    const handleClickAccountOutside = (event) => {
      if (accountRef.current && !accountRef.current.contains(event.target)) {
        setShowMobileMenuAccount(false);
      }
    };
    document.addEventListener("click", handleClickAccountOutside, true);

    const handleClickSubadminMenuOutside = (event) => {
      if (subadminRef.current && !subadminRef.current.contains(event.target)) {
        setShowMobileMenuSubadmin(false);
      }
    };
    document.addEventListener("click", handleClickSubadminMenuOutside, true);
  }, []);

  return (
    <div className="sidebar-section sidebar-top">
      <div className={isSidebarCollapsed ? "mobileLogo" : "deskToplogo"}>
        <div className="d-flex align-items-center">
          {/* <i className="fa-solid fa-capsules mx-2" aria-hidden="true"></i> */}
          <div className="op-name" style={{ fontSize: 17, fontWeight: 900 }}>
            {roleType === "organization"
              ? userCurrrentData?.shortName
              : "PM Control Panel"}
          </div>
        </div>
        <div
          onClick={() => setSidebarCollapsed(!isSidebarCollapsed)}
          className={"close_icon"}
        >
          <i className="fa-solid fa-circle-xmark mx-1"></i>
        </div>
      </div>
      <ul className="side-menu scrollbox">
        <div className="menu-item-area">
          <div className="tooltip">
            <div className="tooltipContent">Dashboard</div>
          </div>
          <NavLink to="/dashboard/analytics" onClick={handleMobileSidebar}>
            <li className={permalink === "analytics" ? "sidebar__active" : ""}>
              <div className="side-menu-item ">
                <i className="fa-solid fa-gauge"></i>
                <span className="op-name">Dashboard</span>
              </div>
            </li>
          </NavLink>
        </div>

        <div className="menu-item-area">
          <div className="tooltip">
            <div className="tooltipContent">Client</div>
          </div>
          <NavLink to="/dashboard/client" onClick={handleMobileSidebar}>
            <li className={permalink === "client" ? "sidebar__active" : ""}>
              <div className="side-menu-item ">
              <i className="fa-solid fa-users"></i>
                <span className="op-name">Client</span>
              </div>
            </li>
          </NavLink>
        </div>

        <div className="menu-item-area">
          <div className="tooltip">
            <div className="tooltipContent">Work Flow</div>
          </div>
          <NavLink to="/dashboard/workFlow" onClick={handleMobileSidebar}>
            <li className={permalink === "workFlow" ? "sidebar__active" : ""}>
              <div className="side-menu-item ">
              <i className="fa-solid fa-person-digging" style={{fontSize: 15}}></i>
                <span className="op-name">Work Flow</span>
              </div>
            </li>
          </NavLink>
        </div>

        <div className="menu-item-area">
          <div className="tooltip">
            <div className="tooltipContent">Subadmin</div>
          </div>
          <NavLink to="/dashboard/subadmin" onClick={handleMobileSidebar}>
            <li className={permalink === "subadmin" ? "sidebar__active" : ""}>
              <div className="side-menu-item ">
              <i className="fa-solid fa-user-tie"></i>
                <span className="op-name">Subadmin</span>
              </div>
            </li>
          </NavLink>
        </div>

        {roleType === "organization" && (
          <>
            <div className="menu-item-area">
              <div className="tooltip">
                <div className="tooltipContent">Reviewers</div>
              </div>
              <NavLink to="/dashboard/reviewers">
                <li
                  className={permalink === "reviewers" ? "sidebar__active" : ""}
                  onClick={handleMobileSidebar}
                >
                  <div className="side-menu-item">
                    <i
                      className="fa-solid fa-user-tie"
                      style={{ marginLeft: 2 }}
                    ></i>
                    <span className="op-name">Reviewers</span>
                  </div>
                </li>
              </NavLink>
            </div>
            <div className="menu-item-area">
              <div className="tooltip">
                <div className="tooltipContent">Validators</div>
              </div>
              <NavLink to="/dashboard/validators">
                <li
                  className={
                    permalink === "validators" ? "sidebar__active" : ""
                  }
                  onClick={handleMobileSidebar}
                >
                  <div className="side-menu-item">
                    <i
                      className="fa-solid fa-user-shield"
                      style={{ marginLeft: 2, marginRight: 5 }}
                    ></i>
                    <span className="op-name">Validators</span>
                  </div>
                </li>
              </NavLink>
            </div>
            <div className="menu-item-area">
              <div className="tooltip">
                <div className="tooltipContent">Packages</div>
              </div>
              <NavLink to="/dashboard/packages">
                <li
                  className={permalink === "packages" ? "sidebar__active" : ""}
                  onClick={handleMobileSidebar}
                >
                  <div className="side-menu-item">
                    <i
                      className="fa-solid fa-cube"
                      style={{ marginLeft: 2, marginRight: 8 }}
                    ></i>
                    <span className="op-name">Packages</span>
                  </div>
                </li>
              </NavLink>
            </div>
          </>
        )}
        {(OrganizationPermission || roleType === "admin") && (
          <div className="menu-item-area">
            <div className="tooltip">
              <div className="tooltipContent">Organizations</div>
            </div>
            <NavLink to="/dashboard/organizations">
              <li
                className={
                  permalink === "organizations" ? "sidebar__active" : ""
                }
                onClick={handleMobileSidebar}
              >
                <div className="side-menu-item">
                  <i className="fa-solid fa-building-columns"></i>
                  <span className="op-name">Organizations</span>
                </div>
              </li>
            </NavLink>
          </div>
        )}

        {roleType === "admin" && (
          <div className="menu-item-area" ref={subadminRef}>
            {!showMobileMenuSubadmin && (
              <div className="tooltip">
                <div className="tooltipContent">Users</div>
              </div>
            )}
            <li
              onClick={() => handleShowMenuSubadmin()}
              style={{ cursor: "pointer" }}
              className={
                permalink === "subadmin" ||
                permalink === "designation" ||
                permalink === "permission"
                  ? "sidebar__active"
                  : ""
              }
            >
              <div className="side-menu-item" style={{ cursor: "pointer" }}>
                <i
                  className="fa-solid fa-user-group"
                  style={{ marginLeft: "0px", marginRight: "7px" }}
                ></i>
                <span className="op-name">
                  Users{" "}
                  {showMobileMenuSubadmin ? (
                    <i className="fa-solid fa-caret-down align_arrow"></i>
                  ) : (
                    <i className="fa-solid fa-caret-right align_arrow"></i>
                  )}
                </span>
              </div>
            </li>
            {!isSidebarCollapsed
              ? showLargeMenuSubadmin && (
                  <ul className="submenu billing">
                    <NavLink
                      to="/dashboard/subadmin"
                      onClick={handleMobileSidebar}
                    >
                      <li
                        className={
                          permalink === "subadmin"
                            ? "sidebar__active mb-1"
                            : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <i className="fa-solid fa-user"></i>
                          <span className="op-name">Subadmin</span>
                        </div>
                      </li>
                    </NavLink>
                    <NavLink
                      to="/dashboard/designation"
                      onClick={handleMobileSidebar}
                    >
                      <li
                        className={
                          permalink === "designation"
                            ? "sidebar__active mb-1"
                            : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <i className="fa-solid fa-user-tie"></i>
                          <span className="op-name">Designation</span>
                        </div>
                      </li>
                    </NavLink>
                    <NavLink
                      to="/dashboard/permission"
                      onClick={handleMobileSidebar}
                    >
                      <li
                        className={
                          permalink === "permission"
                            ? "sidebar__active mb-1"
                            : "mb-1"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                          <i className="fa-solid fa-user-shield"></i>
                          <span className="op-name" style={{ marginLeft: -5 }}>
                            Permission
                          </span>
                        </div>
                      </li>
                    </NavLink>
                  </ul>
                )
              : showMobileMenuSubadmin && (
                  <div className="collapsed__submenu billing box-shadow-max">
                    <NavLink
                      to="/dashboard/subadmin"
                      onClick={() =>
                        setShowMobileMenuSubadmin(!showMobileMenuSubadmin)
                      }
                    >
                      <div
                        className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${
                          permalink === "subadmin"
                            ? "small__sidebar_activeSubmenu"
                            : ""
                        }`}
                      >
                        <i className="fa-solid fa-user"></i>
                        <span>Subadmin</span>
                      </div>
                    </NavLink>
                    <NavLink
                      to="/dashboard/designation"
                      onClick={() =>
                        setShowMobileMenuSubadmin(!showMobileMenuSubadmin)
                      }
                    >
                      <div
                        className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${
                          permalink === "designation"
                            ? "small__sidebar_activeSubmenu"
                            : ""
                        }`}
                      >
                        <i className="fa-solid fa-user-tie"></i>
                        <span>Designation</span>
                      </div>
                    </NavLink>
                    <NavLink
                      to="/dashboard/permission"
                      onClick={() =>
                        setShowMobileMenuSubadmin(!showMobileMenuSubadmin)
                      }
                    >
                      <div
                        className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-2 my-0 ${
                          permalink === "permission"
                            ? "small__sidebar_activeSubmenu"
                            : ""
                        }`}
                      >
                        <i className="fa-solid fa-user-shield"></i>
                        <span style={{ marginLeft: 3 }}>Permission</span>
                      </div>
                    </NavLink>
                  </div>
                )}
          </div>
        )}
        <div className="menu-item-area" ref={accountRef}>
          {!showMobileMenuAccount && (
            <div className="tooltip">
              <div className="tooltipContent">Account</div>
            </div>
          )}
          <li
            onClick={() => handleShowMenuAccount()}
            style={{ cursor: "pointer" }}
            className={
              permalink === "profile" ||
              permalink === "change-password" ||
              permalink === "security" ||
              permalink === "log-history" ||
              permalink === "notifications"
                ? "sidebar__active"
                : ""
            }
          >
            <div className="side-menu-item" style={{ cursor: "pointer" }}>
              <i
                className="fa-solid fa-gear"
                style={{ marginLeft: "1px", marginRight: "10px" }}
              ></i>
              <span className="op-name">
                Account{" "}
                {showMobileMenuAccount ? (
                  <i className="fa-solid fa-caret-down align_arrow"></i>
                ) : (
                  <i className="fa-solid fa-caret-right align_arrow"></i>
                )}
              </span>
            </div>
          </li>
          {!isSidebarCollapsed
            ? showLargeMenuAccount && (
                <ul className="submenu setting">
                  <NavLink
                    to="/dashboard/profile"
                    onClick={handleMobileSidebar}
                  >
                    <li
                      className={
                        permalink === "profile"
                          ? "sidebar__active mb-1"
                          : "mb-1"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                        <i className="fa-solid fa-user"></i>
                        <span className="op-name">Profile</span>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink
                    to="/dashboard/appearance"
                    onClick={handleMobileSidebar}
                  >
                    <li
                      className={
                        permalink === "appearance"
                          ? "sidebar__active mb-1"
                          : "mb-1"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                        <i className="fa-solid fa-gear"></i>
                        <span
                          className="op-name"
                          style={{ marginLeft: "-3px" }}
                        >
                          Appearance
                        </span>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink
                    to="/dashboard/log-data"
                    onClick={handleMobileSidebar}
                  >
                    <li
                      className={
                        permalink === "log-data"
                          ? "sidebar__active mb-1"
                          : "mb-1"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                        <i className="fa-solid fa-clock-rotate-left"></i>
                        <span>Log Data</span>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink
                    to="/dashboard/failed-status"
                    onClick={handleMobileSidebar}
                  >
                    <li
                      className={
                        permalink === "failed-status"
                          ? "sidebar__active mb-1"
                          : "mb-1"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="side-menu-item Sidebar_submenu_padding_btn m-0">
                        <i className="fa-solid fa-ban"></i>
                        <span>Failed Status</span>
                      </div>
                    </li>
                  </NavLink>
                </ul>
              )
            : showMobileMenuAccount && (
                <div className="collapsed__submenu setting box-shadow-max">
                  <NavLink
                    to="/dashboard/profile"
                    onClick={() =>
                      setShowMobileMenuAccount(!showMobileMenuAccount)
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${
                        permalink === "profile"
                          ? "small__sidebar_activeSubmenu"
                          : ""
                      }`}
                    >
                      <i className="fa-solid fa-user"></i>
                      <span>Profile</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/dashboard/appearance"
                    onClick={() =>
                      setShowMobileMenuAccount(!showMobileMenuAccount)
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${
                        permalink === "appearance"
                          ? "small__sidebar_activeSubmenu"
                          : ""
                      }`}
                    >
                      <i className="fa-solid fa-gear"></i>
                      <span style={{ marginLeft: "-3.5px" }}>Appearance</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/dashboard/log-data"
                    onClick={() =>
                      setShowMobileMenuAccount(!showMobileMenuAccount)
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${
                        permalink === "log-data"
                          ? "small__sidebar_activeSubmenu"
                          : ""
                      }`}
                    >
                      <i className="fa-solid fa-clock-rotate-left"></i>
                      <span style={{ marginLeft: "-3.5px" }}>Log Data</span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/dashboard/failed-status"
                    onClick={() =>
                      setShowMobileMenuAccount(!showMobileMenuAccount)
                    }
                  >
                    <div
                      className={`side-menu-item Sidebar_submenu_padding_btn small__sidebar gap-3 mt-0 ${
                        permalink === "failed-status"
                          ? "small__sidebar_activeSubmenu"
                          : ""
                      }`}
                    >
                      <i className="fa-solid fa-ban"></i>
                      <span style={{ marginLeft: "-3.5px" }}>
                        Failed Status
                      </span>
                    </div>
                  </NavLink>
                </div>
              )}
        </div>
        <div className="menu-item-area">
          <div className="tooltip">
            <div className="tooltipContent logout">Logout</div>
          </div>
          <li className="logout">
            <div onClick={logout} className="side-menu-item">
              <i
                className="fa-solid fa-right-from-bracket"
                style={{ marginLeft: "2px", marginRight: "9px" }}
              ></i>
              <span className="op-name">Logout</span>
            </div>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default Sidebar;
