import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { userServices } from "../../../APIServices/usersServices";
import { addressServices } from "../../../APIServices/addressServices";

const AddNewSubadminModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getUsers,
  getUserData,
}) => {
  let [email, setEmail] = useState("");
  let [gender, setGender] = useState("");
  let [address, setAddress] = useState("");
  let [zipCode, setZipCode] = useState("");
  let [cityLists, setCityLists] = useState([]);
  let [city_id, setCityID] = useState("");
  let [stateLists, setStateLists] = useState([]);
  let [state_id, setStateID] = useState("");
  let [countryLists, setCountryLists] = useState([]);
  let [country_Id, setCountryID] = useState("");
  let [lastName, setLastName] = useState("");
  let [firstName, setFirstName] = useState("");
  let [emailError, setEmailError] = useState("");
  let [middleName, setMiddleName] = useState("");
  let [phoneError, setPhoneError] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");

  const getCountries = async () => {
    let data = await addressServices.getCountries();
    setCountryLists(data);
  };

  const getStates = async (id) => {
    let data = await addressServices.getStates(id);
    setStateLists(data);
  };

  const getCities = async (id) => {
    let data = await addressServices.getCities(id);
    setCityLists(data);
  };

  const addUsers = async () => {
    let userData = {};
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.email = email;
    userData.phone = phoneNumber;
    userData.gender = gender;
    userData.Address = address;
    userData.Country = country_Id;
    userData.State = state_id;
    userData.City = city_id;
    userData.zipCode = zipCode;
    userData.role = "subadmin";
    let data = await userServices.addUsers(userData);
    if (data?.status_Code === 200) {
      clearInputField();
      getUsers();
      Hide();
    }
  };

  const clearInputField = () => {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setGender("");
    setAddress("");
    setCountryID("");
    setStateID("");
    setCityID("");
    setZipCode("");
    setEmailError("");
    setPhoneError("");
  };



  const validatEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
    } else {
      setEmailError("Invalide Email,Please Enter valid Email");
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;
    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number Should have 10 digit");
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div
          onClick={() => {
            Hide();
            clearInputField();
          }}
          className="close_modal"
          title="Close"
        >
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              First Name <span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              placeholder="Enter First Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              required
              type="text"
              onChange={(e) => setMiddleName(e.target.value)}
              value={middleName}
              placeholder="Enter Middle Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Last Name <span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              placeholder="Enter Last Name"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Email <span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Enter Email ID"
              onChange={(e) => {
                setEmail(e.target.value);
                validatEmail(e.target.value);
              }}
              value={email}
            />
            {emailError && (
              <span className="message" style={{ color: "red", fontSize: 10 }}>
                {emailError}
              </span>
            )}
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Phone Number <span>*</span>
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                validatePhoneNumber(e.target.value);
              }}
              value={phoneNumber}
              type="number"
              placeholder="Enter Phone Number"
            />
            {phoneError && (
              <span className="message" style={{ color: "red", fontSize: 10 }}>
                {phoneError}
              </span>
            )}
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Gender <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setGender(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Gender</option>
              <option selected={gender === "male"} value="male">
                Male
              </option>
              <option selected={gender === "female"} value="female">
                Female
              </option>
              <option selected={gender === "other"} value="other">
                Other
              </option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Address <span>*</span>
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              as="textarea"
              placeholder="Enter Address"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Country <span>*</span>
            </Form.Label>
            <Form.Select
               className="rounded-1"
               onChange={(e) => {
                 setCountryID(e.target.value);
                 getStates(e.target.value);
               }}
               value={country_Id}
             >
               <option>-Select-</option>
               {countryLists?.length > 0 &&
                 countryLists.map((item, key) => (
                   <option value={item.id} key={key} title={item.id}>
                     {item.name}
                   </option>
                 ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              State <span>*</span>
            </Form.Label>
            <Form.Select
              className="rounded-1"
              onChange={(e) => {
                setStateID(e.target.value);
                getCities(e.target.value);
              }}
              value={state_id}
            >
              <option>-select-</option>
              {stateLists.length > 0 &&
                stateLists.map((item, key) => (
                  <option value={item.id} key={key} title={item.id}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              City <span>*</span>
            </Form.Label>
            <Form.Select
             className="rounded-1"
             onChange={(e) => setCityID(e.target.value)}
             value={city_id}
           >
             <option>-select-</option>
             {cityLists.length > 0 &&
               cityLists.map((item, key) => (
                 <option value={item.id} key={key}>
                   {item.name}
                 </option>
               ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Zip Code <span>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) => setZipCode(e.target.value)}
              value={zipCode}
              required
              type="number"
              placeholder="Enter Zip Code"
            />
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button onClick={clearInputField} className="close_circle">
            Reset
          </Button>
          <Button
            disabled={
              !firstName ||
              !lastName ||
              !email ||
              !phoneNumber ||
              !gender ||
              !address ||
              !country_Id ||
              !state_id ||
              !city_id ||
              !zipCode ||
              emailError ||
              phoneError
            }
            onClick={addUsers}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewSubadminModal;
