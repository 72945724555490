import React, { useEffect, useRef, useState } from "react";
import Dashboard from "./Dashboard";
import Client from "./Client/Client";
import Header from "../component/Header";
import Sidebar from "../component/Sidebar";
import { useParams } from "react-router-dom";
import Users from "./ExternalUsers/UsersLists";
import Profile from "./Account/Profile/Profile";
import LogData from "./Account/LogData/LogData";
import Subadmins from "./User/Subadmin/Subadmins";
import WorkFlowData from "./WorkFlow/WorkFlowData";
import Permission from "./User/Permission/Permission";
import { Col, Container, Row } from "react-bootstrap";
import Designation from "./User/Designation/Designation";
import Appearance from "./Account/Appearance/Appearance";
import RestrictedPage from "../component/RestrictedPage";
import FailedStatus from "./Account/FailedStatus/FailedStatus";

const Panel = ({
  font,
  roleType,
  themeColor,
  permission,
  activeColor,
  pendingColor,
  getThemeFont,
  getThemeColor,
  inActiveColor,
  getStatusColor,
  currentUserData,
  userCurrrentData,
}) => {
  const { permalink } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(
    screenWidth < 767 ? true : false
  );
  const [showLargeMenuSubadmin, setShowLargeMenuSubadmin] = useState(false);
  const [showLargeMenuAccount, setShowLargeMenuAccount] = useState(false);
  const [showLargeMenuEditorControl, setShowLargeMenuEditorControl] =
    useState(false);
  const [showLargeMenuAIAlphaControl, setShowLargeMenuAIAlphaControl] =
    useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
    closeLargeMenu();
  };

  const closeLargeMenu = () => {
    setShowLargeMenuSubadmin(false);
    setShowLargeMenuAccount(false);
    setShowLargeMenuEditorControl(false);
    setShowLargeMenuAIAlphaControl(false);
  };

  const handleMobileSidebar = () => {
    if (screenWidth < 767) {
      setSidebarCollapsed(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setSidebarCollapsed(screenWidth < 767 ? true : false);
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  let OrganizationPermission =
    permission?.addNewOrganizations ||
    permission?.viewOrganizationsDetails ||
    permission?.updateOrganizationsDetails ||
    permission?.changeOrganizationStatus ||
    permission?.exportAllOrganizationData ||
    permission?.viewOrganizationInvitationHistory ||
    permission?.sendNewOrganizationInvitation ||
    permission?.resendOrganizationInvitation ||
    permission?.cancelOrganizationInvitation ||
    roleType === "admin";

  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const th = tableRef.current.querySelector(
          `th:nth-child(${resizingColumn + 1})`
        );
        th.style.width = `${width}px`;
        tableRef.current.classList.add("resizing");
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef.current.querySelectorAll("th").forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col className="p-0">
            <div className="flex-wrapper">
              <div
                id="sidebar"
                className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}
              >
                <Sidebar
                  roleType={roleType}
                  userCurrrentData={userCurrrentData}
                  setShowLargeMenuAccount={setShowLargeMenuAccount}
                  showLargeMenuAccount={showLargeMenuAccount}
                  setShowLargeMenuSubadmin={setShowLargeMenuSubadmin}
                  showLargeMenuSubadmin={showLargeMenuSubadmin}
                  setShowLargeMenuEditorControl={setShowLargeMenuEditorControl}
                  showLargeMenuEditorControl={showLargeMenuEditorControl}
                  setShowLargeMenuAIAlphaControl={
                    setShowLargeMenuAIAlphaControl
                  }
                  showLargeMenuAIAlphaControl={showLargeMenuAIAlphaControl}
                  permalink={permalink}
                  screenWidth={screenWidth}
                  setSidebarCollapsed={setSidebarCollapsed}
                  isSidebarCollapsed={isSidebarCollapsed}
                  OrganizationPermission={OrganizationPermission}
                />
              </div>
              <div className="admin-main">
                <div className="admin-main-header">
                  <i
                    id="menu-btn"
                    className={`fa-solid ${isSidebarCollapsed
                        ? screenWidth < 767
                          ? "fa-solid fa-bars"
                          : "fa-caret-right"
                        : "fa-caret-left"
                      }`}
                    onClick={toggleSidebar}
                    title={
                      isSidebarCollapsed ? "Open Sidebar" : "Close Sidebar"
                    }
                  ></i>
                  <div className="show_nav_title">
                    <i
                      className="fa-solid fa-store mx-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Header
                    userCurrrentData={userCurrrentData}
                    closeLargeMenu={closeLargeMenu}
                    permalink={permalink}
                    screenWidth={screenWidth}
                    handleMobileSidebar={handleMobileSidebar}
                  />
                </div>
                <div className="admin-main-content-area">
                  {permalink === "analytics" ? (
                    <Dashboard
                      OrganizationPermission={OrganizationPermission}
                      permission={permission}
                      themeColor={themeColor}
                      roleType={roleType}
                    />
                  ) : permalink === "organizations" &&
                    ((OrganizationPermission && roleType === "subadmin") ||
                      roleType === "admin") ? (
                    <Users
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                      role={"organization"}
                      userPermission={OrganizationPermission}
                      permission={permission}
                      roleType={roleType}
                    />
                  ) : permalink === "subadmin" ? (
                    <Subadmins onMouseDown={onMouseDown} tableRef={tableRef} />
                  ) : permalink === "designation" && roleType === "admin" ? (
                    <Designation
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                    />
                  ) : permalink === "permission" && roleType === "admin" ? (
                    <Permission onMouseDown={onMouseDown} tableRef={tableRef} />
                  ) : permalink === "profile" ? (
                    <Profile
                      userCurrrentData={userCurrrentData}
                      currentUserData={currentUserData}
                    />
                  ) : permalink === "log-data" ? (
                    <LogData onMouseDown={onMouseDown} tableRef={tableRef} />
                  ) : permalink === "client" ? (
                    <Client onMouseDown={onMouseDown} tableRef={tableRef} />
                  ): permalink === "workFlow" ? (
                    <WorkFlowData onMouseDown={onMouseDown} tableRef={tableRef} />
                  ): permalink === "failed-status" ? (
                    <FailedStatus
                      onMouseDown={onMouseDown}
                      tableRef={tableRef}
                    />
                  ) : permalink === "appearance" ? (
                    <Appearance
                      getThemeColor={getThemeColor}
                      themeColor={themeColor}
                      getThemeFont={getThemeFont}
                      font={font}
                      getStatusColor={getStatusColor}
                      activeColor={activeColor}
                      inActiveColor={inActiveColor}
                      pendingColor={pendingColor}
                    />
                  ) : (
                    <RestrictedPage />
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Panel;
