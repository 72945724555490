import Swal from 'sweetalert2'

export const headersWithAuthWithoutBody = (method, auth) => ({
  method: method,
  headers: auth,
});

export const headersWithAuth = (method, userData, auth) => ({
  method: method,
  headers: auth,
  body: JSON.stringify(userData),
});

export const handleResponse = (response) =>
  response.status_code === 200
    ? Swal.fire({
      icon: 'success',
      title: "Success",
      text: response.message,
      showConfirmButton: false,
      timer: 1500
    })
    : Swal.fire({
      icon: 'error',
      title: "Error",
      text: response.message,
      showConfirmButton: false,
      timer: 1500
    })