import React from "react";
import Moment from "react-moment";
import { Form, Table } from "react-bootstrap";

const ClientListTable = ({ clientList, onMouseDown, tableRef }) => {
    
  return (
    <>
      <div className="table-container overflow-auto">
        <Table ref={tableRef} id="resizable-table" className="custom-table">
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}  style={{ width: 250 }}>Company Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)} style={{ width: 120 }}>Short Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Email</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Mobile Number</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 180 }}>
                Registered Date|Time
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 160 }}>
                Modified Date|Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 5)}
                className="text-center"
                style={{ width: 130 }}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {clientList?.length > 0 &&
              clientList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.companyName}</td>
                  <td>{item.shortName}</td>
                  <td>{item.email}</td>
                  <td>{item.officePhoneNumber}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss" >
                      {item.created_at}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss" >
                      {item.updated_at}
                    </Moment>
                  </td>
                  {/* <td className="hstack justify-content-center border">
                    <div
                      className={`text-center text-capitalize ${
                        item.status === "login" ? "login_text" : "logout_text"
                      }`}
                    >
                      {item.status}
                    </div>
                  </td> */}
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      // onChange={(e) =>
                      //   handleStatusChange(item._id?.$oid, e.target.value)
                      // }
                      className={
                        item.status === "active"
                          ? "active_status"
                          : item.status === "inactive"
                          ? "inactive_status"
                          : "pending_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        {" "}
                        Active{" "}
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                      {item.status === "freeze" && (
                        <option
                          selected={item.status === "freeze"}
                          value="freeze"
                        >
                          Freeze
                        </option>
                      )}
                    </Form.Select>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {clientList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
    </>
  );
};

export default ClientListTable;
