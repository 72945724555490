import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import Moment from "react-moment";
import {Form, Table } from "react-bootstrap";
import UseToggle from "../../Hooks/useToggle";
import UpdateSubadminModal from "./UpdateSubadminModal";
import { userServices } from "../../../APIServices/usersServices";

const SubadminTableData = ({
  subadminList,
  getUserData,
  getUsers,
  permissionList,
  designationList,
  onMouseDown,
  tableRef,
}) => {
  
  const { toggle, setToggle } = UseToggle();

  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [subadminData, setSubadminData] = useState("");

  // const handleUpdateModal = (e, item) => {
  //   setSubadminData(item);
  //   setToggle();
  // };

  // const handleDelete = async (e, id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: `You want to Delete this Subadmin?`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       let data = await userServices.deleteUserData(id);
  //       if (data?.statusCode === 200) {
  //         getUserData();
  //       }
  //     }
  //   });
  // };

  const handleStatusChange = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Subadmin?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.status = status;
        let data = await userServices.updateUser(_id , userData);
        if (data?.status_Code === 200) {
          getUsers();
        }
      }
    });
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Subadmin Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Email Id</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 100 }}>Phone No.</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 80 }}>Gender</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 7)}
                style={{ width: 120 }}
                className="text-center"
              >
                Status
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 8)}
                style={{ width: 180 }}
              >
                Registered Date|Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 8)}
                style={{ width: 160 }}
              >
                Modified Date|Time
              </th>
              {/* <th
                onMouseDown={(e) => onMouseDown(e, 9)}
                style={{ width: 80 }}
                className="text-center"
              >
                Action
              </th> */}
            </tr>
          </thead>
          <tbody ref={ref}>
            {subadminList?.length > 0 &&
              subadminList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.fullName}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td className="text-capitalize">{item.gender}</td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleStatusChange(item._id?.$oid, e.target.value)
                      }
                      className={
                        item.status === "active"
                          ? "active_status"
                          : item.status === "inactive"
                          ? "inactive_status"
                          : "pending_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        {" "}
                        Active{" "}
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                      {item.status === "pending" && (
                        <option
                          selected={item.status === "pending"}
                          value="pending"
                        >
                          Pending
                        </option>
                      )}
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" >
                      {item.created_at}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" >
                      {item.updated_at}
                    </Moment>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {subadminList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdateSubadminModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title={"Update Subadmin"}
        subadminData={subadminData}
        getUserData={getUserData}
        permissionList={permissionList}
        designationList={designationList}
        Submit={"Update"}
      />
    </>
  );
};

export default SubadminTableData;
