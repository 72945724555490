import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { themeSettingsServices } from "../../../APIServices/themeSettingsServices";

const ChangeFont = ({ getThemeFontData, fontData }) => {
  const [font, setFont] = useState("");
  document.documentElement.style.setProperty("--check_font_family", font);

  const updateThemeFontData = async (defaultFont) => {
    let userData = {}
    userData.fontName = defaultFont || font
    let data = await themeSettingsServices.updateThemeFontData(userData)
    if (data?.statusCode === 200) {
      getThemeFontData()
    }
  }

  useEffect(() => {
    setFont(fontData)
  }, [getThemeFontData, fontData]);

  return (
    <div>
      <Row>
        <Form.Group
          as={Col}
          md="4"
          className="add_sponsor_field "
          controlId="validationCustom01"
        >
          <Form.Label>Select Font Family</Form.Label>
          <Form.Select
            className="mb-2"
            onChange={(e) => setFont(e.target.value)}
            aria-label="Default select example"
          >
            <option selected={font === `"Gilroy", serif`} style={{ fontFamily: `"Gilroy", serif` }} value={`"Gilroy", serif`}>Theme Default</option>
            <option selected={font === `"Lato", sans-serif`} style={{ fontFamily: `"Lato", sans-serif` }} value={`"Lato", sans-serif`}>Lato</option>
            <option selected={font === `"Josefin Sans", sans-serif`} style={{ fontFamily: `"Josefin Sans", sans-serif` }} value={`"Josefin Sans", sans-serif`}>Josefin Sans</option>
            <option selected={font === `"Oswald", sans-serif`} style={{ fontFamily: `"Oswald", sans-serif` }} value={`"Oswald", sans-serif`}>Oswald</option>
            <option selected={font === `'Itim', cursive`} style={{ fontFamily: `'Itim', cursive` }} value={`'Itim', cursive`}>Itim</option>
            <option selected={font === `'Karla', sans-serif`} style={{ fontFamily: `'Karla', sans-serif` }} value={`'Karla', sans-serif`}>Karla</option>
            <option selected={font === `'Dancing Script', cursive`} style={{ fontFamily: `'Dancing Script', cursive` }} value={`'Dancing Script', cursive`}>Dancing Script</option>
            <option selected={font === `'Zeyada', cursive`} style={{ fontFamily: `'Zeyada', cursive` }} value={`'Zeyada', cursive`}>Zeyada</option>
            <option selected={font === `'Great Vibes', cursive`} style={{ fontFamily: `'Great Vibes', cursive` }} value={`'Great Vibes', cursive`}>Great Vibes</option>
            <option selected={font === `'Nanum Pen Script', cursive`} style={{ fontFamily: `'Nanum Pen Script', cursive` }} value={`'Nanum Pen Script', cursive`}>Nanum Pen Script</option>
            <option selected={font === `'Gloria Hallelujah', cursive`} style={{ fontFamily: `'Gloria Hallelujah', cursive` }} value={`'Gloria Hallelujah', cursive`}>Gloria Hallelujah</option>
            <option selected={font === `'Lacquer', system-ui`} style={{ fontFamily: `'Lacquer', system-ui` }} value={`'Lacquer', system-ui`}>Lacquer</option>
            <option selected={font === `'Lancelot', serif`} style={{ fontFamily: `'Lancelot', serif` }} value={`'Lancelot', serif`}>Lancelot</option>

          </Form.Select>
          <Form.Label>Font Result</Form.Label>
          <div className="bg-white box-shadow-min border-radius-3 mb-3 widget-style1">
            <div className="checkFontClass">
              <p className="m-0">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Accusantium vel provident veniam magnam inventore dignissimos
                commodi veritatis repudiandae corporis itaque!
              </p>
            </div>
          </div>
          <div className="hstack gap-3 justify-content-end pt-3 footer_button">
            <Button onClick={() => updateThemeFontData(`"Gilroy", serif`)} className="close_circle">Reset</Button>
            <Button onClick={() => updateThemeFontData()}>Save</Button>
          </div>
        </Form.Group>
      </Row>
    </div>
  );
};

export default ChangeFont;
