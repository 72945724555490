import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import UpdateUserModal from "./UpdateUserModal";
import UseToggle from "../Hooks/useToggle";
import Moment from "react-moment";
import { userServices } from "../../APIServices/usersServices";
import Swal from "sweetalert2";

const UsersTableData = ({
  userList,
  getUserData,
  role,
  roleData,
  permission,
  roleType,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle } = UseToggle();

  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [userData, setUserData] = useState("");

  const handleUpdateModal = (e, item) => {
    setUserData(item);
    setToggle();
  };

  const handleCouponModal = (e, item) => {
    setUserData(item);
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this ${
        role === "organization" ? "Organization" : ""
      }?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await userServices.deleteUserData(id);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this ${
        role === "organization" ? "Organization" : ""
      }?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await userServices.changeUserDataStatus(userData);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div className="table-container overflow-auto">
        <Table id="resizable-table" className="custom-table m-0" ref={tableRef}>
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>{roleData} Name</th>
              {role === "organization" && (
                <th onMouseDown={(e) => onMouseDown(e, 2)}>ShortName</th>
              )}
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, role === "organization" ? 3 : 2)
                }
              >
                Email Id
              </th>
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, role === "organization" ? 4 : 3)
                }
                style={{ width: 100 }}
              >
                Phone No.
              </th>
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, role === "organization" ? 5 : 4)
                }
                style={role === "organization" ? { width: 170 } : { width: 80 }}
              >
                {role === "organization" ? "Website" : "Gender"}
              </th>
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, role === "organization" ? 6 : 5)
                }
                style={{ width: "120px" }}
                className="text-center"
              >
                Status
              </th>
              <th
                onMouseDown={(e) =>
                  onMouseDown(e, role === "organization" ? 7 : 6)
                }
                style={{ width: 140 }}
              >
                Registration
              </th>
              {roleType === "subadmin" &&
              (role === "organization"
                ? permission?.updateOrganizationsDetails
                : "") ? (
                <th
                  onMouseDown={(e) =>
                    onMouseDown(e, role === "organization" ? 8 : 7)
                  }
                  style={{ width: "40px" }}
                  className="text-center"
                >
                  Update
                </th>
              ) : roleType === "admin" ? (
                <th
                  onMouseDown={(e) =>
                    onMouseDown(e, role === "organization" ? 8 : 7)
                  }
                  style={{ width: 80 }}
                  className="text-center"
                >
                  Action
                </th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody ref={ref}>
            {userList?.length > 0 &&
              userList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  {role === "organization" ? (
                    <>
                      <td title={item.companyName}>{item.companyName}</td>
                      <td>{item.shortName}</td>
                    </>
                  ) : (
                    <td>{`${item.firstName ? item.firstName : ""} ${
                      item.middleName ? item.middleName : ""
                    } ${item.lastName ? item.lastName : ""}`}</td>
                  )}
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  {role === "organization" ? (
                    <td>{item.website}</td>
                  ) : (
                    <td className="text-capitalize">{item.gender}</td>
                  )}
                  <td className="status_select">
                    <Form.Select
                      disabled={
                        !(role === "organization"
                          ? permission?.changeOrganizationStatus
                          : "") && roleType === "subadmin"
                      }
                      aria-label="Default select example"
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={
                        item.status === "active"
                          ? "active_status"
                          : item.status === "inactive"
                          ? "inactive_status"
                          : "pending_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        {" "}
                        Active{" "}
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                      {item.status === "pending" && (
                        <option
                          selected={item.status === "pending"}
                          value="pending"
                        >
                          Pending
                        </option>
                      )}
                      <option
                        selected={item.status === "freeze"}
                        value="freeze"
                      >
                        Freeze
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.createdAt}
                    </Moment>
                  </td>
                  {roleType === "admin" && (
                    <td className="text-center">
                      <div className="text-center action__buttons">
                        {action === item._id ? (
                          <>
                            <div className="saperate_action_button">
                              <Button
                                onClick={(e) => handleUpdateModal(e, item)}
                                title="Edit"
                                className="rounded-circle"
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </Button>
                              {role === "consultant" && (
                                <Button
                                  onClick={(e) => handleCouponModal(e, item)}
                                  title="Coupon History"
                                  className="rounded-circle"
                                >
                                  <i className="fa-solid fa-tag"></i>
                                </Button>
                              )}
                              <Button
                                onClick={(e) => handleDelete(e, item._id)}
                                title="Delete"
                                className="rounded-circle close_circle"
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            </div>
                            <Button
                              onClick={() => setAction("")}
                              title="Close Action"
                              className="rounded-circle close_circle"
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={() => setAction(item._id)}
                            title="Open Action"
                            className="rounded-circle"
                          >
                            <i className="fa-solid fa-bars"></i>
                          </Button>
                        )}
                      </div>
                    </td>
                  )}
                  {roleType === "subadmin" &&
                    (role === "organization"
                      ? permission?.updateOrganizationsDetails
                      : "") && (
                      <td className="text-center">
                        <div className="text-center action__buttons">
                          <Button
                            onClick={(e) => handleUpdateModal(e, item)}
                            title="Edit"
                            className="rounded-circle"
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                          </Button>
                          {role === "consultant" && (
                            <Button
                              onClick={(e) => handleCouponModal(e, item)}
                              title="Coupon History"
                              className="rounded-circle"
                            >
                              <i className="fa-solid fa-tag"></i>
                            </Button>
                          )}
                        </div>
                      </td>
                    )}
                </tr>
              ))}
          </tbody>
        </Table>
        {userList?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
      <UpdateUserModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        role={role}
        roleData={roleData}
        Title={`Update ${roleData}`}
        userData={userData}
        getUserData={getUserData}
        Submit={"Update"}
      />
    </>
  );
};

export default UsersTableData;
