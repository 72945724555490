import React from "react";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { Form, Table } from "react-bootstrap";
import { userServices } from "../../APIServices/usersServices";

const WorkFlowTableData = ({ workflowData, onMouseDown, tableRef, getAllWorkflow }) => {

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "orange";
      default:
        return "inherit";
    }
  };

  const updateWorkflow = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Workflow Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await userServices.updateWorkflow(_id, data);
        if (results?.status_code === 200) {
          getAllWorkflow();
        }
      }
    });
  };

  return (
    <>
      <div className="table-container overflow-auto">
        <Table ref={tableRef} id="resizable-table" className="custom-table">
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 140 }}>Company Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Workflow Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Workflow ID</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 5)}
                className="text-center"
                style={{ width: 130 }}
              >
                Status
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 6)} style={{ width: 180 }}>
                Registered Date | Time
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Modified Date | Time</th>
            </tr>
          </thead>
          <tbody>
            {workflowData?.length > 0 &&
              workflowData?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.companyName}</td>
                  <td>{item.name}</td>
                  <td>{item.workflowID}</td>
                  <td className="status_select">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) =>
                        updateWorkflow(item.workflowID, e.target.value)
                      }
                      className={
                        item.status === "active"
                          ? "active_status"
                          : item.status === "inactive"
                          ? "inactive_status"
                          : "pending_status"
                      }
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        {" "}
                        Active{" "}
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                      {item.status === "pending" && (
                        <option
                          selected={item.status === "pending"}
                          value="pending"
                        >
                          Pending
                        </option>
                      )}
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss" withTitle>
                      {item.created_at}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss" withTitle>
                      {item.updated_at}
                    </Moment>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {workflowData?.length > 0 ? (
          ""
        ) : (
          <div className="noRecordFound">Record not found</div>
        )}
      </div>
    </>
  );
};

export default WorkFlowTableData;
