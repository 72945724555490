import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { userServices } from "../../APIServices/usersServices";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { addressServices } from "../../APIServices/addressServices";

const UpdateUserModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getUserData,
  userData,
  role,
  roleData,
}) => {
  let [city, setCity] = useState("");
  let [email, setEmail] = useState("");
  let [state, setState] = useState("");
  let [gender, setGender] = useState("");
  let [website, setWebsite] = useState("");
  let [country, setCountry] = useState("");
  let [zipCode, setZipCode] = useState("");
  let [address, setAddress] = useState("");
  let [firstName, setFirstName] = useState();
  let [lastName, setLastName] = useState("");
  let [shortName, setShortName] = useState("");
  let [middleName, setMiddleName] = useState("");
  let [emailError, setEmailError] = useState("");
  let [phoneError, setPhoneError] = useState("");
  let [officePhone, setOfficePhone] = useState("");
  let [companyName, setCompanyName] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [officePhoneError, setOfficePhoneError] = useState("");

  const handleUpdate = async () => {
    Hide();
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update Data this ${roleData}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userDatas = {};
        userDatas.id = userData._id;
        if (role === "organization") {
          userData.companyName = companyName;
          userData.shortName = shortName;
          userData.website = website;
          userData.officePhone = officePhone;
        } else {
          userData.firstName = firstName;
          userData.middleName = middleName;
          userData.lastName = lastName;
          userData.gender = gender;
        }
        userDatas.phone = phoneNumber;
        let data = await userServices.updateUserData(userDatas);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const findCountryName = async () => {
    let data = await addressServices.findCountryName(userData?.countryId);
    setCountry(data?.data);
  };

  const findStateName = async () => {
    let data = await addressServices.findStateName(userData?.stateId);
    setState(data?.data);
  };

  const findCityName = async () => {
    let data = await addressServices.findCityName(userData?.cityId);
    setCity(data?.data);
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;
    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number Should have 10 digits");
    }
  };
  const validateOfficePhoneNumber = (officePhone) => {
    const officephonePattern = /^\d{10}$/;
    if (officephonePattern.test(officePhone)) {
      setOfficePhoneError("");
    } else {
      if (officePhone?.length === 0) {
        setOfficePhoneError("");
      } else {
        setOfficePhoneError(" Office Phone Number Should have 10 digit");
      }
    }
  };

  useEffect(() => {
    findCountryName();
    findStateName();
    findCityName();
    if (role === "organization") {
      setCompanyName(userData?.companyName);
      setShortName(userData?.shortName);
      setWebsite(userData?.website);
      setOfficePhone(userData?.officePhone);
    } else {
      setFirstName(userData?.firstName);
      setMiddleName(userData?.middleName);
      setLastName(userData?.lastName);
      setGender(userData?.gender);
    }
    setEmail(userData?.email);
    setPhoneNumber(userData?.phone);
    setAddress(userData?.address);
    setZipCode(userData?.zipCode);
  }, [userData]);

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {role === "organization" ? (
            <>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  Organization Name <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                  placeholder="Enter Organization Name"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  Short Name <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setShortName(e.target.value)}
                  value={shortName}
                  placeholder="Enter Short Name"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  required
                  type="url"
                  onChange={(e) => setWebsite(e.target.value)}
                  value={website}
                  placeholder="Enter Website"
                />
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  First Name <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  placeholder="Enter First Name"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setMiddleName(e.target.value)}
                  value={middleName}
                  placeholder="Enter Middle Name"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                <Form.Label>
                  Last Name <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  placeholder="Enter Last Name"
                />
              </Form.Group>
            </>
          )}
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Email <span>*</span>
            </Form.Label>
            <Form.Control
              required
              disabled
              type="email"
              placeholder="Enter Email ID"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Phone Number <span>*</span>
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                validatePhoneNumber(e.target.value);
              }}
              value={phoneNumber}
              type="number"
              placeholder="Enter Phone Number"
            />
            {phoneError && (
              <span className="message" style={{ color: "red", fontSize: 10 }}>
                {phoneError}
              </span>
            )}
          </Form.Group>
          {role === "organization" ? (
            <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
              <Form.Label>Office Phone</Form.Label>
              <Form.Control
                required
                onChange={(e) => {
                  setOfficePhone(e.target.value);
                  validateOfficePhoneNumber(e.target.value);
                }}
                value={officePhone}
                type="number"
                placeholder="Enter Office Phone"
              />
              {officePhoneError && (
                <span
                  className="message"
                  style={{ color: "red", fontSize: 10 }}
                >
                  {officePhoneError}
                </span>
              )}
            </Form.Group>
          ) : (
            <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
              <Form.Label>
                Gender <span>*</span>
              </Form.Label>
              <Form.Select
                onChange={(e) => setGender(e.target.value)}
                aria-label="Default select example"
              >
                <option>Select Gender</option>
                <option selected={gender === "male"} value="male">
                  Male
                </option>
                <option selected={gender === "female"} value="female">
                  Female
                </option>
                <option selected={gender === "other"} value="other">
                  Other
                </option>
              </Form.Select>
            </Form.Group>
          )}
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Address<span>*</span>
            </Form.Label>
            <Form.Control
              required
              disabled
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              as="textarea"
              placeholder="Enter Address"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Country <span>*</span>
            </Form.Label>
            <Form.Control required disabled type="text" value={country} />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              State <span>*</span>
            </Form.Label>
            <Form.Control required disabled type="text" value={state} />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              City <span>*</span>
            </Form.Label>
            <Form.Control required disabled type="text" value={city} />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Zip Code <span>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) => setZipCode(e.target.value)}
              value={zipCode}
              required
              disabled
              type="number"
              placeholder="Enter Zip Code"
            />
          </Form.Group>
        </Row>
        <div className="hstack justify-content-end border-top pt-3 footer_button">
          {role === "organization" ? (
            <Button
              disabled={
                !companyName ||
                !shortName ||
                !phoneNumber ||
                emailError ||
                phoneError
              }
              onClick={handleUpdate}
            >
              {Submit}
            </Button>
          ) : (
            <Button
              disabled={
                !firstName ||
                !gender ||
                !lastName ||
                !phoneNumber ||
                emailError ||
                phoneError
              }
              onClick={handleUpdate}
            >
              {Submit}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateUserModal;
