import React, { useEffect, useState } from "react";
import AddNewUserModal from "./AddNewUserModal";
import UseToggle from "../Hooks/useToggle";
import Pagination from "../Hooks/Pagination";
import ExportUserModal from "./ExportUserModal";
import TableHeader from "../../component/TableHeader";
import UsersTableData from "./UsersTableData";
import { userServices } from "../../APIServices/usersServices";
import UsersInvitation from "./UserInvitation/UsersInvitation";
import { invitedDataServices } from "../../APIServices/invitedDataServices";

const UsersLists = ({
  role,
  permission,
  roleType,
  userPermission,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();

  const [userList, setUserList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [invitedList, setInvitedList] = useState([]);

  const getInvitation = async () => {
    let data = await invitedDataServices.getInvitation(role);
    setInvitedList(data?.data);
  };

  const getUserData = async () => {
    let data = await userServices.getUserData(role);
    setUserList(data?.data);
  };

  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = userList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(userList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    userData.roleType = role;
    let data = await userServices.searchUserData(userData);
    setUserList(data?.data);
  };

  const openInvitationHistory = () => {
    setToggle2();
    getInvitation();
  };

  useEffect(() => {
    getUserData();
  }, [role]);

  let inviteOrganizationPermission =
    permission?.viewOrganizationInvitationHistory ||
    permission?.sendNewOrganizationInvitation ||
    permission?.resendOrganizationInvitation ||
    permission?.cancelOrganizationInvitation ||
    roleType === "admin";

  let invitePermission =
    role === "organization" ? inviteOrganizationPermission : "";
  return (
    <>
      <TableHeader
        Search={userPermission}
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getUserData}
        AddNew={
          (role === "organization" ? permission?.addNewOrganizations : "") ||
          roleType === "admin"
        }
        handleAddNew={setToggle}
        titleAddNew={`Add New ${role === "organization" ? "Organization" : ""}`}
        InviteHistory={invitePermission}
        handleInviteHistory={openInvitationHistory}
        titleInviteHistory="Invitation History"
        Export={
          (role === "organization"
            ? permission?.exportAllOrganizationData
            : "") || roleType === "admin"
        }
        handleExport={setToggle1}
        titleExport={`Export ${
          role === "organization" ? "Organization" : ""
        } Data`}
      />
      <UsersTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        userList={currentItems}
        getUserData={getUserData}
        roleType={roleType}
        permission={permission}
        role={role}
        roleData={role === "organization" ? "Organization" : ""}
      />
      <Pagination
        Result={userList}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <AddNewUserModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        Title={`Add New ${role === "organization" ? "Organization" : ""}`}
        getUserData={getUserData}
        role={role}
        Submit={"Submit"}
      />
      <UsersInvitation
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        Show={!toggle2}
        Hide={setToggle2}
        Size={"lg"}
        Title={`${
          role === "organization" ? "Organization" : ""
        } Invitation History`}
        invitedList={invitedList}
        role={role}
        invitePermission={invitePermission}
        roleType={roleType}
        permission={permission}
        setInvitedList={setInvitedList}
        getInvitation={getInvitation}
      />
      <ExportUserModal
        Show={!toggle1}
        Hide={setToggle1}
        role={role}
        Size={"md"}
        Title={`Export ${role === "organization" ? "Organization" : ""}`}
        Submit={"Export"}
      />
    </>
  );
};

export default UsersLists;
