import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { themeSettingsServices } from "../../../APIServices/themeSettingsServices";

const ChangeColor = ({ getThemeColorData, themeColor }) => {
  const [color, setColor] = useState("");
  document.documentElement.style.setProperty("--header_bgColor", `rgb(${color} / 5%)`);
  document.documentElement.style.setProperty("--bg_active_color", `rgb(${color} / 15%)`);
  document.documentElement.style.setProperty("--icon_color", `rgb(${color} / 100%)`);

  const updateThemeColorData = async (defaultColor) => {
    let userData = {}
    userData.color = defaultColor || color
    let data = await themeSettingsServices.updateThemeColorData(userData)
    if (data?.statusCode === 200) {
      getThemeColorData();
    }
  }

  useEffect(() => {
    setColor(themeColor)
  }, [getThemeColorData, themeColor]);

  return (
    <div>
      <Row>
        <Form.Group
          as={Col}
          md="4"
          className="add_sponsor_field "
          controlId="validationCustom01"
        >
          <Form.Label>Select Theme Color</Form.Label>
          <Form.Select
            className="mb-2"
            onChange={(e) => setColor(e.target.value)}
            aria-label="Default select example"
          >
            <option selected={color === "0 183 235"} value={"0 183 235"}>Theme Default</option>
            <option selected={color === "1 170 17"} value={"1 170 17"}>Green</option>
            <option selected={color === "160 0 235"} value={"160 0 235"}>Purple</option>
            <option selected={color === "72 72 72"} value={"72 72 72"}>Black & White</option>
          </Form.Select>
          <div className="hstack gap-3 justify-content-end pt-3 footer_button">
            <Button onClick={() => updateThemeColorData("0 183 235")} className="close_circle">Reset</Button>
            <Button onClick={() => updateThemeColorData()}>Save</Button>
          </div>
        </Form.Group>
      </Row>
    </div>
  );
};

export default ChangeColor;
