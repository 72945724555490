import React, { useEffect, useState } from "react";
import { userServices } from "../../APIServices/usersServices";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { addressServices } from "../../APIServices/addressServices";

const AddNewClient = ({
    Show,
    Hide,
    Size,
    Title,
    Submit,
    getAllClient,
}) => {
    let [email, setEmail] = useState("");
    let [role, setRole] = useState("");
    let [address, setAddress] = useState("");
    let [zipCode, setZipCode] = useState("");
    let [website, setWebsite] = useState("");
    let [cityLists, setCityLists] = useState([]);
    let [city_id, setCityID] = useState("");
    let [stateLists, setStateLists] = useState([]);
    let [state_id, setStateID] = useState("");
    let [countryLists, setCountryLists] = useState([]);
    let [country_Id, setCountryID] = useState("");
    let [shortName, setShortName] = useState("");
    let [emailError, setEmailError] = useState("");
    let [phoneError, setPhoneError] = useState("");
    let [companyName, setCompanyName] = useState("");
    let [officePhoneNumber, setOfficePhoneNumber] = useState("");

    const getCountries = async () => {
        let data = await addressServices.getCountries();
        setCountryLists(data);
    };

    const getStates = async (id) => {
        let data = await addressServices.getStates(id);
        setStateLists(data);
    };

    const getCities = async (id) => {
        let data = await addressServices.getCities(id);
        setCityLists(data);
    };

    const addClient = async () => {
        let userData = {};
        userData.companyName = companyName;
        userData.shortName = shortName;
        userData.website = website;
        userData.email = email;
        userData.officePhoneNumber = officePhoneNumber;
        userData.Address = address;
        userData.Country = country_Id;
        userData.State = state_id;
        userData.City = city_id;
        userData.zipCode = zipCode;
        userData.role = "client";
        let data = await userServices.addClient(userData);
        if (data?.status_code === 200) {
            clearInputField();
            getAllClient();
            Hide();
        }
    };

    const clearInputField = () => {
        setCompanyName("");
        setShortName("");
        setWebsite("");
        setEmail("");
        setOfficePhoneNumber("");
        setRole("");
        setAddress("");
        setCountryID("");
        setStateID("");
        setCityID("");
        setZipCode("");
        setEmailError("");
        setPhoneError("");
    };

 

    const validatEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailPattern.test(email)) {
            setEmailError("");
        } else {
            setEmailError("Invalid Email,Please Enter valid Email");
        }
    };

    const validateofficePhoneNumber = (officePhoneNumber) => {
        const phonePattern = /^\d{10}$/;
        if (phonePattern.test(officePhoneNumber)) {
            setPhoneError("");
        } else {
            setPhoneError("Phone Number Should have 10 digit");
        }
    };

    useEffect(() => {
        getCountries();
    }, []);

    return (
        <Modal
            show={Show}
            onHide={Hide}
            size={Size}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>{Title}</Modal.Title>
                <div
                    onClick={() => {
                        Hide();
                        clearInputField();
                    }}
                    className="close_modal"
                    title="Close"
                >
                    X
                </div>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                        <Form.Label>
                            Company Name <span>*</span>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                            placeholder="Enter Company Name"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                        <Form.Label>Short Name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            onChange={(e) => setShortName(e.target.value)}
                            value={shortName}
                            placeholder="Enter Short Name"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                        <Form.Label>
                            Website Name <span>*</span>
                        </Form.Label>
                        <Form.Control
                            required
                            type="email"
                            onChange={(e) => setWebsite(e.target.value)}
                            value={website}
                            placeholder="Enter Website Name"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="8" className="add_sponsor_field mb-3">
                        <Form.Label>
                            Email <span>*</span>
                        </Form.Label>
                        <Form.Control
                            required
                            type="email"
                            placeholder="Enter Email ID"
                            onChange={(e) => {
                                setEmail(e.target.value);
                                validatEmail(e.target.value);
                            }}
                            value={email}
                        />
                        {emailError && (
                            <span className="message" style={{ color: "red", fontSize: 10 }}>
                                {emailError}
                            </span>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                        <Form.Label>
                            Office Phone Number <span>*</span>
                        </Form.Label>
                        <Form.Control
                            required
                            onChange={(e) => {
                                setOfficePhoneNumber(e.target.value);
                                validateofficePhoneNumber(e.target.value);
                            }}
                            value={officePhoneNumber}
                            type="number"
                            placeholder="Enter Phone Number"
                        />
                        {phoneError && (
                            <span className="message" style={{ color: "red", fontSize: 10 }}>
                                {phoneError}
                            </span>
                        )}
                    </Form.Group>

                    <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
                        <Form.Label>
                            Address <span>*</span>
                        </Form.Label>
                        <Form.Control
                            required
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                            as="textarea"
                            placeholder="Enter Address"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                        <Form.Label>
                            Country <span>*</span>
                        </Form.Label>
                        <Form.Select
                           className="rounded-1"
                           onChange={(e) => {
                             setCountryID(e.target.value);
                             getStates(e.target.value);
                           }}
                           value={country_Id}
                         >
                           <option>-Select-</option>
                           {countryLists?.length > 0 &&
                             countryLists.map((item, key) => (
                               <option value={item.id} key={key} title={item.id}>
                                 {item.name}
                               </option>
                             ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                        <Form.Label>
                            State <span>*</span>
                        </Form.Label>
                        <Form.Select
                            className="rounded-1"
                            onChange={(e) => {
                              setStateID(e.target.value);
                              getCities(e.target.value);
                            }}
                            value={state_id}
                          >
                            <option>-select-</option>
                            {stateLists.length > 0 &&
                              stateLists.map((item, key) => (
                                <option value={item.id} key={key} title={item.id}>
                                  {item.name}
                                </option>
                              ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                        <Form.Label>
                            City <span>*</span>
                        </Form.Label>
                        <Form.Select
                          className="rounded-1"
                          onChange={(e) => setCityID(e.target.value)}
                          value={city_id}
                        >
                          <option>-select-</option>
                          {cityLists.length > 0 &&
                            cityLists.map((item, key) => (
                              <option value={item.id} key={key}>
                                {item.name}
                              </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                        <Form.Label>
                            Zip Code <span>*</span>
                        </Form.Label>
                        <Form.Control
                            onChange={(e) => setZipCode(e.target.value)}
                            value={zipCode}
                            required
                            type="number"
                            placeholder="Enter Zip Code"
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
                        <Form.Label>
                            Role <span>*</span>
                        </Form.Label>
                        <Form.Select
                            onChange={(e) => setRole(e.target.value)}
                            aria-label="Default select example"
                        >
                            <option>Select Role</option>
                            <option selected={role === "client"} value="client">
                                Client
                            </option>
                            <option selected={role === "subadmin"} value="subadmin">
                                Subadmin
                            </option>
                        </Form.Select>
                    </Form.Group>

                </Row>
                <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
                    <Button onClick={clearInputField} className="close_circle">
                        Reset
                    </Button>
                    <Button
                        disabled={
                            !companyName ||
                            !address ||
                            !officePhoneNumber ||
                            !city_id ||
                            !state_id ||
                            !country_Id ||
                            !zipCode ||
                            phoneError
                          }
                        onClick={addClient}
                    >
                        {Submit}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddNewClient;
