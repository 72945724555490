import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const TableHeader = ({
  Search,
  AddNew,
  Invite,
  Export,
  Coupon,
  Credit,
  Status,
  Analytics,
  titleCoupon,
  SearchValue,
  titleAddNew,
  titleInvite,
  titleCredit,
  titleExport,
  TextButton1,
  TextButton2,
  TextButton3,
  handleSearch,
  handleCredit,
  handleExport,
  handleCoupon,
  handleInvite,
  handleAddNew,
  InviteHistory,
  setSearchData,
  titleAnalytics,
  handleAnalytics,
  TextButtonName1,
  TextButtonName2,
  TextButtonName3,
  SearchReverseData,
  handleTextButton2,
  handleTextButton3,
  handleTextButton1,
  titleInviteHistory,
  handleInviteHistory,
  SubscriptionTransection,
  titleSubscriptionTransection,
  handleSubscriptionTransection,
}) => {
  const [filter, setFilter] = useState(false);
  return (
    <div className="hstack justify-content-between mb-2 sponsors_section_filter">
      <div className="hstack gap-2">
        {Search && (
          <>
            {filter ? (
              <>
                <Button
                  onClick={() => {
                    setFilter(false);
                    SearchValue
                      ? SearchReverseData(SearchValue)
                      : SearchReverseData();
                  }}
                  title="Cancel"
                  className="close_circle"
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
                <div className="hstack">
                  <Form.Control
                    required
                    onChange={(e) => {
                      handleSearch(e.target.value);
                      setSearchData(e.target.value);
                    }}
                    type="search"
                    placeholder="Search"
                  />
                  <Button
                    title="Search"
                    onClick={() => handleSearch()}
                    style={{ borderRadius: "0px 3px 3px 0px", width: 40 }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </Button>
                </div>
              </>
            ) : (
              <Button onClick={() => setFilter(true)} title="Filter">
                <i className="fa-solid fa-filter"></i>
              </Button>
            )}
          </>
        )}
        {AddNew && (
          <Button onClick={handleAddNew} title={titleAddNew}>
            <i className="fa-solid fa-plus"></i>
          </Button>
        )}
        {Invite && (
          <Button onClick={handleInvite} title={titleInvite}>
            <i className="fa-solid fa-paper-plane"></i>
          </Button>
        )}
        {InviteHistory && (
          <Button onClick={handleInviteHistory} title={titleInviteHistory}>
            <i className="fa-solid fa-history"></i>
          </Button>
        )}
        {SubscriptionTransection && (
          <Button
            onClick={handleSubscriptionTransection}
            title={titleSubscriptionTransection}
          >
            <i className="fa-solid fa-hand-holding-dollar"></i>
          </Button>
        )}
        {Coupon && (
          <Button onClick={handleCoupon} title={titleCoupon}>
            <i className="fa-solid fa-tag"></i>
          </Button>
        )}
        {Credit && (
          <Button onClick={handleCredit} title={titleCredit}>
            <i className="fa-solid fa-credit-card"></i>
          </Button>
        )}

        {Analytics && (
          <Button onClick={handleAnalytics} title={titleAnalytics}>
            <i className="fa-solid fa-chart-line"></i>
          </Button>
        )}
      </div>
      <div className="hstack gap-3">
        {TextButton1 && (
          <Button
            onClick={() => handleTextButton1()}
            title={TextButtonName1}
            className={`w-100 ${!Status && "activeStatusButton"}`}
          >
            <span className={Status === "active" ? "statusButton" : "spanTextSize txt_color"}>
              <b>{TextButtonName1}</b>
            </span>
          </Button>
        )}
        {TextButton2 && (
          <Button
            onClick={() => handleTextButton2()}
            title={TextButtonName2}
            className={`w-100 ${Status === "active" && "activeStatusButton"}`}
          >
            <span className={Status === "active" ? "spanTextSize" : "statusButton"}>
              <b>{TextButtonName2}</b>
            </span>
          </Button>
        )}
        {TextButton3 && (
          <Button
            onClick={() => handleTextButton3()}
            title={TextButtonName3}
            className={`w-100 ${Status === "inactive" && "activeStatusButton"}`}
          >
            <span className={Status === "inactive" ? "spanTextSize" : "statusButton"}>
              <b>{TextButtonName3}</b>
            </span>
          </Button>
        )}
        {Export && (
          <Button onClick={handleExport} title={titleExport}>
            <i className="fa-solid fa-download"></i>
          </Button>
        )}
      </div>
    </div>
  );
};

export default TableHeader;
