import React, { useEffect, useState } from "react";
import LogTableData from "./LogTableData";
import UseToggle from "../../Hooks/useToggle";
import Pagination from "../../Hooks/Pagination";
import ExportLogDataModal from "./ExportLogDataModal";
import TableHeader from "../../../component/TableHeader";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";

const LogData = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle } = UseToggle();
  const [logData, setLogData] = useState([]);

  const loginActivities = async () => {
    let data = await loginActivitiesServices.loginActivities();
    setLogData(data?.data);
  };

  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = logData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(logData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % logData?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    loginActivities();
  }, []);
  return (
    <>
      <TableHeader Export handleExport={setToggle} />
      <LogTableData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        logData={currentItems}
      />
      <Pagination
        Result={logData}
        itemsPerPage={itemsPerPage}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
      <ExportLogDataModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Export Log History"}
        Submit={"Export"}
      />
    </>
  );
};

export default LogData;
